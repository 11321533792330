import Api from '.'

class Product extends Api {
	constructor() {
		super()
	}

	getAll({ page = 1, limit = 10, query }) {
		return this.get('ebook', {
			addAuthorization: true,
			params: {
				page,
				limit,
				query,
			},
		})
	}

	create(body) {
		return this.post('ebook', {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	update(id, body) {
		return this.patch(`ebook/${id}`, {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	deleteById(id) {
		return this.delete(`ebook/${id}`, {
			addAuthorization: true,
		})
	}
}

export default Product
