import Api from '.'

class Customization extends Api {
	constructor() {
		super()
	}

	getData() {
		return this.get('theme-config')
	}

	update(id, body) {
		return this.patch(`theme-config/${id}`, {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}
}

export default Customization
