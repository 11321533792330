import axios from 'axios'

class Api {
	http = axios.create()
	handleResponse = (response) => response.data

	constructor() {
		this.http.defaults.baseURL = `${process.env.GATSBY_API}/api`
		this.http.defaults.headers.common['Content-Type'] = 'application/json'
		this.http.interceptors.request.use(
			(config) => {
				return config
			},
			(error) => {
				return Promise.reject(error)
			}
		)
	}

	jwtToken() {
		return localStorage.getItem('access-token')
	}

	async get(endpoint, { headers, params, addAuthorization } = {}) {
		const response = await this.http.get(endpoint, {
			params,
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})
		return this.handleResponse(response)
	}

	async post(endpoint, { headers, params, body, addAuthorization } = {}) {
		const response = await this.http.post(endpoint, body, {
			params,
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})
		return this.handleResponse(response)
	}

	async put(endpoint, { headers, params, body, addAuthorization } = {}) {
		const response = await this.http.put(endpoint, body, {
			params,
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})
		return this.handleResponse(response)
	}

	async patch(endpoint, { headers, params, body, addAuthorization } = {}) {
		const response = await this.http.patch(endpoint, body, {
			params,
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})
		return this.handleResponse(response)
	}

	async delete(endpoint, { headers, params, addAuthorization } = {}) {
		const response = await this.http.delete(endpoint, {
			params,
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})
		return this.handleResponse(response)
	}
}

export default Api
