import Api from '.'

class Payment extends Api {
	constructor() {
		super()
	}

	getPaymentStatus(id) {
		return this.get(`payment/pix/${id}`, {
			addAuthorization: true,
		})
	}

	getPurchaseHistory({ page = 1, limit = 1000 }) {
		return this.get('payment', {
			addAuthorization: true,
			params: {
				page,
				limit,
			},
		})
	}

	finalize(payments, method) {
		return this.post(`payment/${method}`, {
			addAuthorization: true,
			body: {
				payments,
			},
		})
	}

	sendToEmail() {
		return this.get('payment/history/send-mail', {
			addAuthorization: true,
		})
	}
}

export default Payment
