import { createContext, useContext, useEffect, useState } from 'react'

const Cart = createContext({
	items: [],
	count: 0,
	quantity: {
		items: 0,
		tickets: 0,
	},
	price: 0,
	add: () => {},
	remove: () => () => {},
	update: () => {},
	reset: () => {},
})

const toCountAndPrice = (current, { tickets, ticketValue }) => {
	const [currentTicketsCount, currentPrice] = current
	return [currentTicketsCount + tickets, currentPrice + tickets * ticketValue]
}

export const CartProvider = ({ children }) => {
	const [items, setItems] = useState([])

	const [ticketsCount, price] = items.reduce(toCountAndPrice, [0, 0])

	const quantity = {
		items: items.length,
		tickets: ticketsCount,
	}

	const update = (id) => (amount) => {
		setItems((prevCart) => {
			const index = prevCart.findIndex((item) => item._id === id)
			const item = prevCart[index]

			if (item) {
				if (amount <= item.limitTickets) item.tickets = amount
				if (amount <= 0) item.tickets = 0
			}

			return [...prevCart]
		})
	}

	const add = (newItem) => {
		const alreadyAddedItem = items.find((item) => item._id === newItem._id)

		if (alreadyAddedItem) {
			const handleUpdate = update(newItem._id)
			handleUpdate(alreadyAddedItem.tickets + newItem.tickets)
		} else {
			setItems((prevCart) => [...prevCart, newItem])
		}
	}

	const remove = (id) => () => setItems((prevCart) => prevCart.filter((item) => item._id !== id))

	const reset = () => setItems([])

	return (
		<Cart.Provider value={{ items, quantity, price, add, remove, update, reset }}>
			{children}
		</Cart.Provider>
	)
}

export const useCartContext = () => useContext(Cart)
