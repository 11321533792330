import { createContext, useContext, useState } from 'react'

const ModalContext = createContext({
	isOpen: false,
	content: '',
	state: {},
	open: () => () => {},
	close: () => {},
	navigate: () => () => {},
	previous: () => {},
})

export const ModalProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [history, setHistory] = useState([])
	const content = history.length ? history[history.length - 1] : ''

	const [state, setState] = useState({})
	const updateState = (newState) => setState(newState ?? {})

	const open = (contentName, newState) => () => {
		setIsOpen(true)
		setHistory([contentName])
		updateState(newState)
	}

	const close = () => {
		setIsOpen(false)
		setHistory([])
		updateState()
	}

	const navigate = (contentName, newState) => () => {
		if (contentName) {
			setHistory((prevHistory) => [...prevHistory, contentName])
			updateState(newState)
		}
	}

	const previous = () => {
		if (history.length > 1) setHistory((prevHistory) => prevHistory.slice(0, -1))
		else close()
	}

	return (
		<ModalContext.Provider value={{ isOpen, content, state, open, close, navigate, previous }}>
			{children}
		</ModalContext.Provider>
	)
}

export const useModalContext = () => useContext(ModalContext)
