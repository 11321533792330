import { createContext, useContext, useEffect, useState } from 'react'

import { useUserContext } from '@contexts/User'

import { api } from '@services'

const PurchaseContext = createContext({
	history: [],
	currentPayment: {},
	getByCampaignId: () => {},
	finalize: () => {},
	waitPayment: () => {},
	sendToEmail: () => {},
})

export const PurchaseProvider = ({ children }) => {
	const { isAuthenticated } = useUserContext()

	const [history, setHistory] = useState([])
	const [currentPayment, setCurrentPayment] = useState({})

	const getByCampaignId = (id) => history.find((history) => history.campaignId === id)

	const sendToEmail = () => api.payment.sendToEmail()

	const waitPayment = () => {
		return new Promise((resolve) => {
			const intervalId = setInterval(async () => {
				const paymentStatus = await api.payment.getPaymentStatus(currentPayment._id)
				if (paymentStatus.status === 'PAID') {
					clearInterval(intervalId)
					resolve()
				}
			}, 10000)
		})
	}

	const finalize = (cart, method) => {
		const toPayments = ({ _id, tickets }) => ({ campaignId: _id, numTickets: tickets })
		const payments = cart.map(toPayments)
		return api.payment.finalize(payments, method).then(setCurrentPayment)
	}

	const mountHistory = (purchases) => {
		const byPaidStatus = ({ status }) => status === 'PAID'
		const paidPurchases = purchases.filter(byPaidStatus).reduce((acc, paidPurchase) => {
			const tickets = []

			paidPurchase.tickets.forEach((ticket) => {
				const byCampaignId = ({ campaignId }) => ticket.campaignId === campaignId
				const alreadyAdded = tickets.find(byCampaignId)

				if (alreadyAdded) {
					alreadyAdded.count++
					alreadyAdded.numbers.push(ticket.ticketNumber)
				} else {
					tickets.push({
						count: 1,
						campaignId: ticket.campaignId,
						value: ticket.value,
						date: paidPurchase.updatedAt,
						numbers: [ticket.ticketNumber],
					})
				}
			})

			return [...acc, ...tickets]
		}, [])

		setHistory(paidPurchases)
	}

	useEffect(() => {
		if (isAuthenticated) api.payment.getPurchaseHistory({}).then(mountHistory)
	}, [isAuthenticated])

	return (
		<PurchaseContext.Provider
			value={{ history, currentPayment, getByCampaignId, finalize, waitPayment, sendToEmail }}
		>
			{children}
		</PurchaseContext.Provider>
	)
}

export const usePurchaseContext = () => useContext(PurchaseContext)
