import axios from 'axios'

const states = {
	AC: 'Acre',
	AL: 'Alagoas',
	AP: 'Amapá',
	AM: 'Amazonas',
	BA: 'Bahia',
	CE: 'Ceará',
	DF: 'Distrito Federal',
	ES: 'Espírito Santo',
	GO: 'Goiás',
	MA: 'Maranhão',
	MT: 'Mato Grosso',
	MS: 'Mato Grosso do Sul',
	MG: 'Minas Gerais',
	PA: 'Pará',
	PB: 'Paraíba',
	PR: 'Paraná',
	PE: 'Pernambuco',
	PI: 'Piauí',
	RJ: 'Rio de Janeiro',
	RN: 'Rio Grande do Norte',
	RS: 'Rio Grande do Sul',
	RO: 'Rondônia',
	RR: 'Roraima',
	SC: 'Santa Catarina',
	SP: 'São Paulo',
	SE: 'Sergipe',
	TO: 'Tocantins',
}

class Address {
	http = axios.create()
	handleResponse = (response) => {
		const { uf, localidade, logradouro, bairro } = response.data
		return {
			state: states[uf],
			city: localidade,
			street: logradouro,
			neighborhood: bairro,
		}
	}

	constructor() {
		this.http.defaults.baseURL = 'https://viacep.com.br/ws'
		this.http.defaults.headers.common['Content-Type'] = 'application/json'
		this.http.interceptors.request.use(
			(config) => {
				return config
			},
			(error) => {
				return Promise.reject(error)
			}
		)
	}

	async getByZipCode(zipCode) {
		const response = await this.http.get(`/${zipCode}/json`)
		return this.handleResponse(response)
	}
}

export default Address
