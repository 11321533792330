const extractDigits = (value) => value.replace(/[^\d]+/g, '')

const formatWithThousandsSeparator = (num) => num.toLocaleString('pt-BR')

const formatCurrency = (num, currency = 'BRL') => {
	const value = isNaN(num) ? 0 : num
	return value.toLocaleString('pt-BR', {
		style: 'currency',
		currency,
		minimumFractionDigits: 2,
	})
}

const formatPhone = (num) => {
	if (!num) return num
	const digits = num.split('')
	const phone = digits.reduce((mask, digit) => mask.replace('#', digit), '(##) #####-####')
	return phone
}

export default { extractDigits, formatWithThousandsSeparator, formatCurrency, formatPhone }
