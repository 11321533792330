import { createContext, useContext, useEffect, useState } from 'react'

import { api } from '@services'

const User = createContext({
	user: {},
	isAuthenticated: undefined,
	isAdmin: undefined,
	loadUser: () => {},
	register: () => {},
	completeRegistration: () => {},
	login: () => {},
	logout: () => {},
	forgotPassword: () => {},
	resetPassword: () => {},
	updatePassword: () => {},
})

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState({})
	const isAuthenticated = user.authenticated
	const isAdmin = user.admin

	const authenticate = (data) => {
		const accessToken = data?.access_token
		if (accessToken) {
			localStorage.setItem('access-token', accessToken)
		}

		const user = data?.user
		if (user) {
			const { cpf, email, fullName, wallet, isRegisterComplete, role } = user
			setUser({
				cpf,
				email,
				name: fullName,
				walletBalance: wallet?.balance ?? 0,
				isRegisterComplete,
				authenticated: true,
				admin: role === 'ADMIN',
			})
		} else {
			setUser({
				authenticated: false,
			})
		}
	}

	const loadUser = () => {
		const searchParams = new URLSearchParams(window.location.search)
		const token = searchParams.get('activate')

		if (token) window.history.replaceState({}, document.title, window.location.origin)

		const authFlow = token ? api.user.activateUser(token) : api.user.me()
		authFlow.then(authenticate).catch()
	}

	const register = (payload) => {
		const affiliateId = localStorage.getItem('affiliate')
		return api.user.signUp({ affiliateId, ...payload })
	}

	const completeRegistration = (payload) => api.user.completeRegistration(payload).then(loadUser)

	const login = (payload) => api.user.signIn(payload).then(authenticate)

	const forgotPassword = (payload) => api.user.forgotPassword(payload)

	const resetPassword = (payload) => api.user.resetPassword(payload)

	const updatePassword = (payload) => api.user.updatePassword(payload)

	const logout = () => {
		localStorage.removeItem('access-token')
		setUser({ authenticated: false })
	}

	useEffect(loadUser, [])

	return (
		<User.Provider
			value={{
				user,
				isAuthenticated,
				isAdmin,
				loadUser,
				register,
				completeRegistration,
				login,
				logout,
				forgotPassword,
				resetPassword,
				updatePassword,
			}}
		>
			{children}
		</User.Provider>
	)
}

export const useUserContext = () => useContext(User)
