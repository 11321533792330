import Api from '.'

class Banner extends Api {
	constructor() {
		super()
	}

	getAll({ page = 1, limit = 10, query }) {
		return this.get('banner', {
			addAuthorization: true,
			params: {
				page,
				limit,
				query,
			},
		}).catch((e) => {
			console.log(e)
			return []
		})
	}

	create(body) {
		return this.post('banner', {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	update(id, body) {
		return this.patch(`banner/${id}`, {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	deleteById(id) {
		return this.delete(`banner/${id}`, {
			addAuthorization: true,
		})
	}
}

export default Banner
