import { createContext, useContext, useEffect, useState } from 'react'

import { api } from '@services'

const CampaignContext = createContext({ campaigns: [], noCampaigns: false, getById: () => {} })

export const CampaignProvider = ({ children }) => {
	const [campaigns, setCampaigns] = useState([])
	const [noCampaigns, setNoCampaigns] = useState(false)

	const getById = (id) => campaigns.find((campaigns) => campaigns._id === id)

	useEffect(() => {
		api.campaign.getAll({ query: { status: 'STARTED' } }).then((data) => {
			if (Boolean(data.length)) setCampaigns(data)
			else setNoCampaigns(true)
		})
	}, [])

	return (
		<CampaignContext.Provider value={{ campaigns, noCampaigns, getById }}>
			{children}
		</CampaignContext.Provider>
	)
}

export const useCampaignContext = () => useContext(CampaignContext)
