import Api from '.'

class Campaign extends Api {
	constructor() {
		super()
	}

	getAll({ page = 1, limit = 10, query }) {
		return this.get('campaign', {
			params: {
				page,
				limit,
				query,
			},
		})
	}

	create(body) {
		return this.post('campaign', {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	update(id, body) {
		return this.patch(`campaign/${id}`, {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	start(id) {
		return this.patch(`campaign/start/${id}`, {
			addAuthorization: true,
		})
	}

	finish(id, body) {
		return this.patch(`campaign/finish/${id}`, {
			addAuthorization: true,
			body,
		})
	}

	deleteById(id) {
		return this.delete(`campaign/${id}`, {
			addAuthorization: true,
		})
	}
}

export default Campaign
