import Banner from './api/banner'
import Affiliate from './api/affiliate'
import User from './api/user'
import Campaign from './api/campaign'
import Product from './api/product'
import Payment from './api/payment'
import Drawn from './api/drawn'
import Ticket from './api/ticket'
import Customization from './api/customization'

import Address from './externalApi'

export const api = {
	affiliate: new Affiliate(),
	banner: new Banner(),
	user: new User(),
	campaign: new Campaign(),
	product: new Product(),
	payment: new Payment(),
	drawn: new Drawn(),
	ticket: new Ticket(),
	customization: new Customization(),
}

export const externalApi = {
	address: new Address(),
}
