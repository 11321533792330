import Api from '.'

class Ticket extends Api {
	constructor() {
		super()
	}

	getWinnerPreview(campaignId, ticketNumber) {
		if (campaignId && ticketNumber) {
			return this.get(`ticket/winner-preview/${campaignId}/${ticketNumber}`, {
				addAuthorization: true,
			})
		}
	}
}

export default Ticket
