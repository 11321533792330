import Api from '.'

class Affiliate extends Api {
	constructor() {
		super()
	}

	getAll({ page = 1, limit = 10, query }) {
		return this.get('affiliate', {
			addAuthorization: true,
			params: {
				page,
				limit,
				query,
			},
		}).catch((e) => {
			console.log(e)
			return []
		})
	}

	create({
		name,
		'national-id': nationalId,
		email,
		cellphone,
		'rev-share': revShare,
		'is-active': isActive,
		'zip-code': zipcode,
		state,
		city,
		neighborhood,
		street,
		'address-number': addressNumber,
		complement,
	}) {
		return this.post('affiliate', {
			addAuthorization: true,
			body: {
				name,
				nationalId,
				email,
				cellphone,
				revShare: revShare / 100,
				isActive,
				address: {
					zipcode,
					state,
					street,
					city,
					neighborhood,
					street,
					addressNumber,
					complement,
				},
			},
		})
	}

	update(
		id,
		{
			name,
			'national-id': nationalId,
			email,
			cellphone,
			'rev-share': revShare,
			'is-active': isActive,
			'zip-code': zipcode,
			state,
			city,
			neighborhood,
			street,
			'address-number': addressNumber,
			complement,
		}
	) {
		return this.patch(`affiliate/${id}`, {
			addAuthorization: true,
			body: {
				name,
				nationalId,
				email,
				cellphone,
				revShare: revShare / 100,
				isActive,
				address: {
					zipcode,
					state,
					street,
					city,
					neighborhood,
					street,
					addressNumber,
					complement,
				},
			},
		})
	}

	deleteById(id) {
		return this.delete(`affiliate/${id}`, {
			addAuthorization: true,
		})
	}
}

export default Affiliate
