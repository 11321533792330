import '@fontsource/poppins/100.css'
import '@fontsource/poppins/200.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'
import '@fontsource/poppins/900.css'

import { ModalProvider } from '@contexts/Modal'
import { UserProvider } from '@contexts/User'
import { CampaignProvider } from '@contexts/Campaign'
import { PurchaseProvider } from '@contexts/Purchase'
import { CartProvider } from '@contexts/Cart'
import { CustomizationProvider } from '@contexts/Customization'

export const wrapRootElement = ({ element }) => (
	<CustomizationProvider>
		<ModalProvider>
			<UserProvider>
				<CampaignProvider>
					<PurchaseProvider>
						<CartProvider>{element}</CartProvider>
					</PurchaseProvider>
				</CampaignProvider>
			</UserProvider>
		</ModalProvider>
	</CustomizationProvider>
)
