import Api from '.'

class Drawn extends Api {
	constructor() {
		super()
	}

	getAll({ page = 1, limit = 10, query, options } = {}) {
		return this.get('draw', {
			params: {
				page,
				limit,
				query,
				options,
			},
		})
	}
}

export default Drawn
