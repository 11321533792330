import Api from '.'
import number from '@utils/number'

class User extends Api {
	constructor() {
		super()
	}

	me() {
		return this.get('user/me', { addAuthorization: true }).catch((error) => {
			console.log(error.message)
		})
	}

	activateUser(token) {
		return this.patch(`user/activate-email/${token}`).catch((error) => {
			console.log(error.message)
		})
	}

	signIn({ email, password }) {
		return this.post('user/sign-in', {
			addAuthorization: true,
			body: { email, password },
		}).catch((_) => {
			throw new Error('Erro ao entrar')
		})
	}

	signUp({ email, password, 'birth-date': birthDate, 'email-marketing': canSendNews, affiliateId }) {
		return this.post('user/sign-up', {
			addAuthorization: true,
			body: {
				email,
				password,
				birthDate,
				canSendNews,
				affiliateId,
			},
		}).catch((_) => {
			throw new Error('Erro ao criar usuário')
		})
	}

	completeRegistration({ name: fullName, cpf, phone }) {
		return this.patch('user/finish-register', {
			addAuthorization: true,
			body: {
				fullName,
				phone: number.extractDigits(phone),
				cpf: number.extractDigits(cpf),
			},
		}).catch((_) => {
			throw new Error('Erro ao completar cadastro')
		})
	}

	forgotPassword({ email }) {
		return this.post('user/forgot-password', {
			addAuthorization: true,
			body: { email },
		}).catch((_) => {
			throw new Error('Erro ao solicitar mudança de senha')
		})
	}

	resetPassword({ token, 'new-password': newPassword }) {
		return this.post('user/reset-password', {
			addAuthorization: true,
			body: { token, newPassword },
		}).catch((_) => {
			throw new Error('Erro ao recriar a senha')
		})
	}

	updatePassword({ 'current-password': currentPassword, 'new-password': newPassword }) {
		return this.post('user/change-password', {
			addAuthorization: true,
			body: { currentPassword, newPassword },
		}).catch((_) => {
			throw new Error('Erro ao mudar a senha')
		})
	}
}

export default User
