import { createContext, useContext, useEffect, useState } from 'react'
import chroma from 'chroma-js'

import { api } from '@services'

const DEFAULT_CUSTOMIZATIONS = {
	primaryColor: '#312e97',
	secondaryColor: '#e9e9f7',
	accentColor: '#26bb2a',
	erorColor: '#ff5252',
	warningColor: '#eaed35',
	isDarkTheme: false,
}

const CustomizationContext = createContext({
	isLoading: false,
	customizations: {},
	setupCustomizations: async () => {},
})

export const CustomizationProvider = ({ children }) => {
	const getStoredCustomizations = () => {
		const rawStoredCustomizations = localStorage.getItem('customizations')
		return rawStoredCustomizations && JSON.parse(rawStoredCustomizations)
	}

	const [customizations, setCustomizations] = useState(getStoredCustomizations())
	const [isLoading, setIsLoading] = useState(false)

	const setupCustomizations = async () => {
		setIsLoading(true)

		const apiCustomizations = await api.customization.getData()
		const storedCustomizations = getStoredCustomizations()

		const currentCustomizations = apiCustomizations || storedCustomizations || DEFAULT_CUSTOMIZATIONS
		localStorage.setItem('customizations', JSON.stringify(currentCustomizations))

		setCustomizations(currentCustomizations)
		setIsLoading(false)
	}

	const createColors = () => {
		if (customizations) {
			const root = document.documentElement
			const suffix = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]

			const createVariations = ({ name, color, amount }) => {
				const colors = ['white', color, 'black']
				if (customizations.isDarkTheme) colors.reverse()

				const scale = chroma.scale(colors).mode('lrgb').colors(13)
				scale.shift()
				scale.pop()

				for (let i = 0; i < amount; i++) {
					root.style.setProperty(`--color-${name}-${suffix[i]}`, scale[i])
				}
			}

			createVariations({
				name: 'primary',
				color: customizations.primaryColor,
				amount: 11,
			})

			createVariations({
				name: 'secondary',
				color: customizations.secondaryColor,
				amount: 11,
			})

			createVariations({
				name: 'accent',
				color: customizations.accentColor,
				amount: 11,
			})

			createVariations({
				name: 'error',
				color: customizations.errorColor,
				amount: 11,
			})

			createVariations({
				name: 'warning',
				color: customizations.warningColor,
				amount: 11,
			})

			root.style.setProperty(
				'--color-background-primary',
				customizations.isDarkTheme ? '#121212' : '#ffffff'
			)

			root.style.setProperty(
				'--color-background-secondary',
				customizations.isDarkTheme ? '#000000' : '#f2f2f2'
			)

			const textColors = customizations.isDarkTheme
				? ['#191721', '#47454d', '#65646a', '#959499', '#b8b7ba', '#e8e8e9']
				: ['#e8e8e9', '#b8b7ba', '#959499', '#65646a', '#47454d', '#191721']

			textColors.forEach((textColor, index) => {
				root.style.setProperty(`--color-text-${suffix[index]}`, textColor)
			})
		}
	}

	useEffect(() => {
		setupCustomizations()
	}, [])

	useEffect(createColors, [customizations])

	return (
		<CustomizationContext.Provider value={{ isLoading, customizations, setupCustomizations }}>
			{children}
		</CustomizationContext.Provider>
	)
}

export const useCustomizationContext = () => useContext(CustomizationContext)
